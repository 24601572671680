import React from 'react'
import styles from '../styles_v2/screen4.module.css'

// import Footer from '../Footer'

export default function Screen4() {
  return (
    <div className={styles.full_container}>
      {/* <Footer /> */}
      <p>Copyright © All Rights Reserved by Cesar Ferreyra-Mansilla</p>
    </div>
  )
}
